import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { formatNumber } from 'react-native-currency-input';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import { useGetEntity, useUpdateSystemEntity } from '@/api/entities';
import locale from '@/internationalization';
import { currencyFormat, currencyFormatFloat } from '@/utils/strUtils';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import { formatOptions } from '@/utils/currencyMask';

export const useSystemConfig = (entity: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData, isLoading: entityLoading } = useGetEntity(entity, !!entity);
  
  const [loading, setLoading] = useState(false);
  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);
  const [APISuccess, setAPISuccess] = useState(true);
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');
  
  const [systemMinRecurrentPrice, setSystemMinRecurrentPrice] = useState<number | null>(0);
  const [systemMinProductQuantity, setSystemMinProductQuantity] = useState('');
  const [isSchedule, setIsSchedule] = useState(false);
  const [isSectorsOnThePurchaseTotem, setIsSectorsOnThePurchaseTotem] = useState(false);
  const [isSectorsOnTheKitchenTotem, setIsSectorsOnTheKitchenTotem] = useState(false);
  const [showAdminClientPurchase  , setShowAdminClientPurchase] = useState(false);
  const [showStaffPurchase  , setShowStaffPurchase] = useState(false);
  const [discountForEmployeesChildren, setDiscountForEmployeesChildren] = useState(false);
  const [showObservations, setShowObservations] = useState(false);
  const [id, setId] = useState('');
  const [city, setCity] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [country, setCountry] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [state, setState] = useState('');
  const [phone, setPhone] = useState('');
  const [schedulePeriodLimit, setSchedulePeriodLimit] = useState(''); 
  const [maxScheduleLimit, setMaxScheduleLimit] = useState(''); 
  const queryClient = useQueryClient();
  const mutation = useUpdateSystemEntity();

  const { goBack } = useNavigate();

  const update = async () => {
    setLoading(true);

    try {
      const response = await mutation.mutateAsync({
        id,
        city,
        name,
        address,
        addressLine2,
        addressNumber,
        country,
        zipCode,
        state,
        phone,
        systemMinRecurrentPrice: currencyFormat(
          formatNumber(systemMinRecurrentPrice || 0, formatOptions),
        ),
        systemMinProductQuantity: parseInt(systemMinProductQuantity, 10),
        isSchedule,
        schedulePeriodLimit,
        isSectorsOnThePurchaseTotem,
        isSectorsOnTheKitchenTotem,
        showAdminClientPurchase,
        discountForEmployeesChildren,
        showStaffPurchase,
        maxScheduleLimit,
        showObservations,
      });

      const data = await response.json();
      if (response.status !== 200) {
        setLoading(false);
        setHasAPIError(true);
        const err = data.code ? locale.t(`errors.${data.code}`) : locale.t('errors.400');
        setAPIErrorMessage(err);
        return;
      }

      await queryClient.invalidateQueries(['entity', entity]);

      setLoading(false);
      setHasAPIError(false);
      setAPISuccess(true);
      setApiSuccessMessage(locale.t('success.200'));
    } catch (e) {
      setLoading(false);
      console.error('useSystem.submit', e);
      setHasAPIError(true);
      setAPIErrorMessage(locale.t('errors.500'));
    }
  };

  useEffect(() => {
    if (entityData && !entityLoading) {
      setId(entityData?.id);
      setName(entityData?.name);
      setCity(entityData?.city);
      setAddress(entityData?.address);
      setAddressLine2(entityData?.addressLine2);
      setAddressNumber(entityData?.addressNumber);
      setCountry(entityData?.country);
      setZipCode(entityData?.zipCode);
      setState(entityData?.state);
      setPhone(entityData?.phone);
      setSchedulePeriodLimit(entityData?.schedulePeriodLimit);
      setSystemMinRecurrentPrice(currencyFormatFloat(entityData?.systemMinRecurrentPrice));
      setSystemMinProductQuantity(entityData?.systemMinProductQuantity?.toString() || '');
      setIsSchedule(entityData?.isSchedule)
      setIsSectorsOnThePurchaseTotem(entityData?.isSectorsOnThePurchaseTotem)
      setShowAdminClientPurchase(entityData?.showAdminClientPurchase)
      setDiscountForEmployeesChildren(entityData?.discountForEmployeesChildren)
      setShowStaffPurchase(entityData?.showStaffPurchase)
      setMaxScheduleLimit(entityData?.maxScheduleLimit || '')
      setIsSectorsOnTheKitchenTotem(entityData?.isSectorsOnTheKitchenTotem)
      setShowObservations(entityData?.showObservations)
    }
  }, [entityData]);

  return {
    adminData,
    entityData,
    loading,
    apiSuccessMessage,
    APISuccess,
    APIErrorMessage,
    hasAPIError,
    systemMinProductQuantity,
    systemMinRecurrentPrice,
    isSchedule,
    schedulePeriodLimit,
    isSectorsOnThePurchaseTotem,
    showAdminClientPurchase,
    discountForEmployeesChildren,
    showStaffPurchase,
    maxScheduleLimit,
    isSectorsOnTheKitchenTotem,
    showObservations,
    setMaxScheduleLimit,
    setShowStaffPurchase,
    setDiscountForEmployeesChildren,
    setShowAdminClientPurchase,
    setSchedulePeriodLimit,
    setSystemMinProductQuantity,
    setIsSchedule,
    update,
    goBack,
    setSystemMinRecurrentPrice,
    setIsSectorsOnThePurchaseTotem,
    setIsSectorsOnTheKitchenTotem,
    setShowObservations,
  };
};
