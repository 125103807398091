import React from 'react';

import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { formatNumber } from 'react-native-currency-input';
import { Header } from '@/components/header';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { Paper } from '@/components/paper';
import { colors } from '@/consts';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { Subheader } from '@/components/subheader';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { PageStyles } from '@/styles/common/PageStyles';
import { Styles } from './RechargeTransferCreatePage.styles';
import { FinanceTabs } from '@/components/financetabs';
import { RechargeTransferCreatePageTypes } from './RechargeTransferCreatePage.types';
import { useManualRechargeCreate } from './RechargeTransferCreatePage.functions';
import { Routes } from '@/navigation/routes';
import { SearchableInput } from '@/components/searchableinput/SearchableInput';
import { currencyFormatFloat } from '@/utils/strUtils';
import { formatOptions } from '@/utils/currencyMask';
import { ErrorHandler } from '@/components/errorhandler';

export const RechargeTransferCreatePage = ({
  route: {
    params: { entity },
  },
}: RechargeTransferCreatePageTypes) => {
  const {
    adminData,
    entityData,
    clientOf,
    clientTo,
    disabled,
    clientsSearchList,
    clientIdOf,
    clientIdTo,
    clientOfVc,
    clientToVc,
    value,
    hasAPIError,
    APIErrorMessage,
    setClientToVcValue,
    setClientIdOf,
    setClientIdTo,
    setClientOfVc,
    setClientToVc,
    setClientOf,
    setClientTo,
    setValue,
    recharge,
    cancel,
  } = useManualRechargeCreate(entity);
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        role={adminData?.adminType?.type}
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.FinanceRechargeTransfer}
      />

      <Container>
        <EntitiesPageTitle
          title={locale.t('rechargeTransferCreate.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
          <FinanceTabs
            entityId={entity}
            manualRecharge={false}
            extract={false}
            rechargeTransfer
            removeBalance={false}
          />
          <View style={[PaperColumns.tableContainer]}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.imageWrapper} />
                  <Text style={PageStyles.subtitle}>
                    {locale.t('rechargeTransferCreate.new')}
                  </Text>
                  <Text style={PageStyles.content}>
                    {locale.t('rechargeTransferCreate.content')}
                  </Text>
                </View>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.subtitleHeader}>
                    <View />
                    <View style={PageStyles.headerButtons}>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('rechargeTransferCreate.cancel')}
                          bgColor={colors.newEntity.entitiesBtn}
                          labelColor={colors.newEntity.entitiesBtnTitle}
                          height={42}
                          onPress={cancel}
                          fontSize={14}
                        />
                      </View>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('rechargeTransferCreate.save')}
                          height={42}
                          fontSize={14}
                          onPress={recharge}
                          disabled={
                            disabled ||
                            clientIdOf === '' ||
                            clientIdTo === '' ||
                            value === 0 ||
                            value === null
                          }
                        />
                      </View>
                    </View>
                  </View>
                  {hasAPIError && <ErrorHandler message={APIErrorMessage} />}
                  <View style={Styles.inputContainer}>
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <SearchableInput
                          value={clientOf}
                          onChange={inputValue => setClientOf(inputValue)}
                          label={locale.t('rechargeTransferCreate.form.of')}
                          data={clientsSearchList}
                          onSelect={item => {
                            setClientIdOf(item.value);
                            setClientOf(item.label);
                            setClientOfVc(
                              formatNumber(
                                currencyFormatFloat(item.metadata as number),
                                formatOptions,
                              ),
                            );
                            setClientToVcValue(item.metadata as number)
                          }}
                        />
                        {clientOf.length > 0 && (
                          <Text style={Styles.textBalance}>
                            {locale.t('finance.balance')}: {clientOfVc}
                          </Text>
                        )}
                      </View>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <SearchableInput
                          value={clientTo}
                          onChange={inputValue => setClientTo(inputValue)}
                          label={locale.t('rechargeTransferCreate.form.to')}
                          data={clientsSearchList}
                          onSelect={item => {
                            setClientIdTo(item.value);
                            setClientTo(item.label);
                            setClientToVc(
                              formatNumber(
                                currencyFormatFloat(item.metadata as number),
                                formatOptions,
                              ),
                            );
                          }}
                        />

                        {clientTo.length > 0 && (
                          <Text style={Styles.textBalance}>
                            {locale.t('finance.balance')}: {clientToVc}
                          </Text>
                        )}
                      </View>
                    </View>
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <MTextInput
                          label={locale.t('rechargeTransferCreate.form.value')}
                          value={value}
                          prefix="R$ "
                          onChangeValue={setValue}
                          isCurrency
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default RechargeTransferCreatePage;
