import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import { useGetEntity } from '@/api/entities';
import locale from '../../internationalization';
import { useGetTotems, useUpdateTotem } from '@/api/totem';
import { Totem } from '@/types/totem';
import { Routes } from '@/navigation/routes';
import useNavigate from '@/navigation/routes/hooks/useNavigate';

export const useTotemProfiles = (entity: string) => {
  const { goToEntityPage } = useNavigate();

  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: totems, isLoading: loading } = useGetTotems(entity, !!entity);

  const [totemList, setTotemList] = useState<any[]>([]);

  const queryClient = useQueryClient();
  const mutator = useUpdateTotem();

  const goToEdit = (idTotem: string) => {
    const index = totemList.findIndex((c) => c.id === idTotem);
    const { id } = totemList[index];
    goToEntityPage(Routes.Totem, {
      params: {
        entity,
        id,
      },
      screen: Routes.TotemProfilesCreate,
    });
  };

  const formatTotemList = (list: Totem[]) => {
    const listFormatted = list.map((totem) => ({
      id: totem.id,
      username: totem.user.username,
      terminalId: totem.terminalId,
      role: totem.user.role === 'kitchen' ? locale.t('kitchen') : locale.t('totem_role'),
      isActive: {
        label: totem.user.isActive ? locale.t('active') : locale.t('inactive'),
        value: totem.user.isActive,
      },
      manage: '',
    }));

    setTotemList(listFormatted);
  };

  const updateStatus = async (val: boolean, idTotem: string) => {
    const index = totemList.findIndex((c) => c.id === idTotem);
    if (totems[index]) {
      const totem = totems[index] as Totem;

      await mutator.mutateAsync({
        isActive: val,
        id: totem.id,
      });
      await queryClient.invalidateQueries('totems');
    }
  };

  useEffect(() => {
    if (totems?.length && !loading) {
      formatTotemList(totems);
    }
  }, [totems]);

  return {
    adminData,
    loading,
    totemList,
    entityData,
    updateStatus,
    goToEdit,
  };
};
