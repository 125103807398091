import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetAdmin, useGetAdminId } from '@/api/admin';
import { emailIsValid } from '@/utils/strUtils';
import { useNavigate } from '@/navigation/routes/hooks/useNavigate';
import locale from '@/internationalization';
import { Routes } from '@/navigation/routes';
import { useGetEntity } from '@/api/entities';
import { TotemType } from '@/types/totemType';
import { useCreateTotem, useGetTotem, useUpdateTotem } from '@/api/totem';
import { AdminType } from '@/types';

export const useTotemCreate = (entity: string, id: string) => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: entityData } = useGetEntity(entity, !!entity);
  const { data: entityTotem } = useGetTotem(id, !!id);
  const [adminType, setAdminType] = useState<AdminType>(AdminType.Owner);

  const { goBack, goToEntityPage } = useNavigate();

  const [email, setEmail] = useState('');
  const [terminalId, setTerminalId] = useState(undefined);
  const [totemType, setTotemType] = useState<TotemType>(TotemType.Kitchen);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [APIErrorMessage, setAPIErrorMessage] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);

  const queryClient = useQueryClient();
  const mutator = useCreateTotem();
  const mutatorUpdate = useUpdateTotem();

  const goToStep2 = () => {
    setStep1(true);
    setStep2(true);
  };

  const goToStep1 = () => {
    setStep1(true);
    setStep2(false);
  };

  const emailChange = (e: string) => {
    setEmail(e);
    setEmailError(emailIsValid(e));
  };

  const createTotem = () =>
    mutator.mutateAsync({
      entity,
      password,
      role: totemType,
      username: email,
      terminalId,
    });

  const updateTotem = (i: string) => 
    mutatorUpdate.mutateAsync({
      id: i,
      password,
      username: email,
      role: totemType,
      terminalId,
    });

  const submit = async () => {
    setLoading(true);
    try {
      const result = id ? await updateTotem(id) : await createTotem();

      const data = await result.json();

      if (result.status !== 201 && result.status !== 200) {
        setLoading(false);
        setHasAPIError(true);
        const err = data.code
          ? locale.t(`errors.${data.code}`)
          : locale.t('errors.400');
        setAPIErrorMessage(err);
        return;
      }

      setLoading(false);

      await queryClient.invalidateQueries('totems');

      goToEntityPage(Routes.Totem, {
        params: { entity },
        screen: Routes.TotemProfiles,
      });
    } catch (e) {
      console.error('totemCreate.submit', e);
      setHasAPIError(true);
      setAPIErrorMessage(locale.t('errors.500'));
      setLoading(false);
    }
  };

  useEffect(() => {
    goToStep1();
  }, []);

  useEffect(() => {
    if (entityTotem) {
      setEmail(entityTotem?.user.username);
      setTotemType(entityTotem?.user.role);
      setIsEditing(true);
      setTerminalId(entityTotem?.terminalId);
    }
  }, [entityTotem]);

  useEffect(() => {
    if (adminData?.adminType?.type === 'menupass_super') {
      setAdminType(AdminType.Manager);
    }
  }, [adminData]);

  return {
    adminData,
    email,
    password,
    confirmPassword,
    adminType,
    setPassword,
    setConfirmPassword,
    step1,
    step2,
    emailError,
    setTotemType,
    loading,
    APIErrorMessage,
    hasAPIError,
    totemType,
    isEditing,
    entityData,
    terminalId,
    setTerminalId,
    emailChange,
    goBack,
    goToStep1,
    goToStep2,
    submit,
  };
};
